import Splide from "@splidejs/splide";
import anime from "animejs";
import SmoothScroll from "smooth-scroll";
import * as ScrollMagic from "scrollmagic";

/**
 * Splide config object contains all the options that are needed
 * to initialize the hero fade effect.
 */
const splide_config = {
  type: "fade",
  perPage: 1,
  arrows: false,
  pagination: false,
  interval: 4500,
  autoplay: false,
  rewind: true,
  drag: false,
  pauseOnFocus: false,
  pauseOnHover: false,
  speed: 1400,
};

const splide_config_2 = {
  type: "fade",
  perPage: 1,
  arrows: false,
  pagination: false,
  interval: 5000,
  autoplay: true,
  rewind: true,
  drag: false,
  pauseOnFocus: false,
  pauseOnHover: false,
  speed: 2000,
};

var experience_splide;
var current_slide = 0;
const MAX_SLIDES = document.querySelectorAll(".exp-wrapper").length;

/**
 * try catch for hero fade effect
 */
try {
  experience_splide = new Splide("#hero_slide", splide_config).mount();
  new Splide("#gallery_slide", splide_config_2).mount();

  document.querySelector("#exp-btn-next").addEventListener("click", () => {
    if (current_slide == MAX_SLIDES - 1) {
      current_slide = 0;
      experience_splide.go(current_slide);
    } else {
      current_slide = current_slide + 1;
      experience_splide.go(current_slide);
    }
  });
  document.querySelector("#exp-btn-prev").addEventListener("click", () => {
    if (current_slide == 0) {
      current_slide = MAX_SLIDES - 1;
      experience_splide.go(current_slide);
    } else if (current_slide != 0 && current_slide < MAX_SLIDES) {
      current_slide = current_slide - 1;
      experience_splide.go(current_slide);
    }
  });
} catch (e) {
  console.warn("Problem exporting Splide List");
}

/**
 * Website animations
 *  - Nav bar
 *  - All scroll Animations
 */
try {
  // Add scrollmagic controller
  let controller = new ScrollMagic.Controller();

  /**
   *
   */
  let kasol_img_timeline = anime({
    targets: [".big-img-section"],
    translateY: ["150", "0"],
    width: ["80%","100%"],
    autoplay: false,
    easing: "easeInOutCubic",
  });

  // init controller

  // create a scene
  new ScrollMagic.Scene({
    triggerElement: ".experiences-section",
    duration: 1200, // the scene should last for a scroll distance of 100px
    offset: 360, // start this scene after scrolling for 50px
  })
    .addTo(controller)
    .on("progress", (event) => {
      kasol_img_timeline.seek(event.progress * kasol_img_timeline.duration);
    });
} catch (e) {
  console.warn("Pronblem exporting AnimeJs");
}

try {
  new SmoothScroll('a[href*="#"]', {
    speed: 1600,
    speedAsDuration: true,
    easing: "easeInOutCubic",
    offset: 260,
    updateURL: false,
  });
} catch (e) {
  console.warn("Problem exporting smooth scroll");
}

try{
  document.getElementById('show-more-btn').addEventListener('click', () => {
    document.querySelector('.testimonial-section').style.height = 'auto';
    document.querySelector('.show-shadow').style.display = 'none';
    document.querySelector('#show-more-btn').style.display = 'none';
  });
} catch (e) {
  console.warn("Problem with buttons");
}